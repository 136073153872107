<template>
  <div class="index-layout">
    <div class="grid">
      <div class="grid__column grid__column--75 grid__column--support">
        <div class="support">
          <div class="support__head">
            <h1>Часто задаваемые вопросы</h1>
            <a v-if="$resize && $mq.below(1024)" :href="$root.config.vk_question_url" class="button button--vk"><span class="icon icon--vk-button"></span><span class="vk-text">Обратиться через вк</span></a>
            <a v-else :href="$root.config.vk_question_url" class="button button--vk"><span class="icon icon--vk-button"></span>Обратиться через вк</a>
            <router-link to="a" :to="{name: 'Tickets'}" class="button button--vk" style="width: 100px">Тикеты</router-link>
          </div>
          <Accordion :faq-data="faqData"/>
        </div>
      </div>

      <div class="grid__column grid__column--25 grid__column--side-right grid__column--chat">
        <Chatbox :online="online" />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Accordion from '@/components/Accordion/Accordion.vue';
import Chatbox from '@/components/Chatbox/Chatbox.vue';

export default {
  name: 'Home',
  props: ['online'],
  components: {
    Accordion,
    Chatbox
  },
  watch: {
    '$root.config'() {
      this.faqData = [
        {
          header: 'Правила чата',
          text: 'Ваш никнейм не должен содержать названия или ссылки других\n' +
              '                        сайтов.<br>\n' +
              '                        Запрещено упоминать ютуб каналы в чате<br>\n' +
              '                        Запрещена реклама своих дискорд каналов<br>\n' +
              '                        Сделки запрещены<br>\n' +
              '                        Упоминание посторонних сайтов запрещено<br>\n' +
              '                        Попрошайничество запрещено.<br>\n' +
              '                        Оскорбления в чью-либо сторону запрещены.<br>\n' +
              '                        Запрещено скидывать любые ссылки/id/промики.<br>\n' +
              '                        Каждый обязан соблюдать правила чата и с уважением относиться к участникам чата. В противном<br>\n' +
              '                        случае, выдается бан/мут, на усмотрение администратора.<br>\n' +
              '                        Запрещено отправлять сообщения, написанные только КАПСОМ, больше 50% символов КАПСОМ<br>\n' +
              '                        относятся к этому правилу.<br>\n' +
              '                        Сообщение не должно содержать лишь 1 символ.<br>\n' +
              '                        Модераторы могут, на свое усмотрение, выдавать мут/бан, не разъясняя причины.'
        },
        {
          header: 'Советы для хорошей игры',
          text: 'Наш сайт очень требователен к хорошему и стабильному интернет\n' +
              '                        соеденению, также нужен современный браузер. Рекомендуем:<br>\n' +
              '                        1. Использовать браузеры Google Chrome / Opera<br>\n' +
              '                        2. Отключать видео/трансляции на ютубе/twitch итд<br>\n' +
              '                        3. Отключать онлайн музыку<br>\n' +
              '                        4. Отключать демонстрацию экрана в Discord / Skype.<br>\n' +
              '                        Все это повышает ваш пинг, и из за этого могут быть лаги/задержки.<br>\n' +
              '\n' +
              '                        Рекомендуем также записывать игру на сайте в случае, если вы наблюдаете какие-либо лаги. В\n' +
              '                        будущем запись можно будет предъявить для того, чтобы рассмотрели заявку на возврат.'
        },
        {
          header: 'Мультиакки',
          text: 'На нашем сайте запрещено использование нескольких аккаунтов сразу. Одна из причин - это способ забрать промо коды у других людей, а также использовать бонусные программы для увеличения личного дохода. За использование нескольких аккаунтов могут применяться некоторые меры: блокировка промокодов, изъятие вещей, закрытие выводов, закрытие доступа к аккаунту'
        },
        {
          header: 'Почему при выводе скин возвращается в инвентарь?',
          text: 'Возможно в данный момент на маркете нет нужного предмета, в этом случае выберите другой предмет или подождите его появления. Также не забудьте проверить доступность трейдов на Вашем аккаунте.'
        },
        {
          header: 'Почему я не могу использовать промокод?',
          text: 'На нашем сайте ЗАПРЕЩЕНО ИМЕТЬ ДВА И БОЛЕЕ АККАУНТА- за это доступ к промокодам закрывается. Если у вас только один аккаунт, то, вероятнее всего, вы уже использовали сегодня промокод либо его лимит был исчерпан. Также промокоды могут быть заблокированы по усмотрению администриации за слив промокодов в чат'
        },
        {
          header: 'Почему не пришли деньги на баланс?',
          text: 'Подождите 5-30 минут, если проблема не решается, обратитесь в поддержку.'
        },
        {
          header: 'Как обменять баланс на предмет?',
          text: 'Нажмите кнопку «Размен» в правом нижнем углу экрана.'
        },
        {
          header: 'Как пополнить баланс?',
          text: 'Пополнить баланс можно, нажав на кнопку "Пополнить" в правой верхней части экрана любым удобным для вас способом, в том числе скинами'
        },
        {
          header: 'У вашего сайта есть группа ВКонтакте?',
          text: `Да, у нас есть группа <a href="${this.$root.config.vk_group}">ВКонтакте</a>`
        },
        {
          header: 'Где взять промокод?',
          text: `Промо-коды публикуются в нашей группе <a href="${this.$root.config.vk_group}">ВКонтакте</a>`
        }
      ]
    }
  },
  data() {
    return {
      faqData: [
        {
          header: 'Правила чата',
          text: 'Ваш никнейм не должен содержать названия или ссылки других\n' +
              '                        сайтов.<br>\n' +
              '                        Запрещено упоминать ютуб каналы в чате<br>\n' +
              '                        Запрещена реклама своих дискорд каналов<br>\n' +
              '                        Сделки запрещены<br>\n' +
              '                        Упоминание посторонних сайтов запрещено<br>\n' +
              '                        Попрошайничество запрещено.<br>\n' +
              '                        Оскорбления в чью-либо сторону запрещены.<br>\n' +
              '                        Запрещено скидывать любые ссылки/id/промики.<br>\n' +
              '                        Каждый обязан соблюдать правила чата и с уважением относиться к участникам чата. В противном<br>\n' +
              '                        случае, выдается бан/мут, на усмотрение администратора.<br>\n' +
              '                        Запрещено отправлять сообщения, написанные только КАПСОМ, больше 50% символов КАПСОМ<br>\n' +
              '                        относятся к этому правилу.<br>\n' +
              '                        Сообщение не должно содержать лишь 1 символ.<br>\n' +
              '                        Модераторы могут, на свое усмотрение, выдавать мут/бан, не разъясняя причины.'
        },
        {
          header: 'Советы для хорошей игры',
          text: 'Наш сайт очень требователен к хорошему и стабильному интернет\n' +
              '                        соеденению, также нужен современный браузер. Рекомендуем:<br>\n' +
              '                        1. Использовать браузеры Google Chrome / Opera<br>\n' +
              '                        2. Отключать видео/трансляции на ютубе/twitch итд<br>\n' +
              '                        3. Отключать онлайн музыку<br>\n' +
              '                        4. Отключать демонстрацию экрана в Discord / Skype.<br>\n' +
              '                        Все это повышает ваш пинг, и из за этого могут быть лаги/задержки.<br>\n' +
              '\n' +
              '                        Рекомендуем также записывать игру на сайте в случае, если вы наблюдаете какие-либо лаги. В\n' +
              '                        будущем запись можно будет предъявить для того, чтобы рассмотрели заявку на возврат.'
        },
        {
          header: 'Реферальная система',
          text: 'Каждый пользователь, который зарегистрировался по Вашей реферальной ссылке и пополнил баланс, принесет Вам процент на баланс'
        },
        {
          header: 'Мультиакки',
          text: 'На нашем сайте запрещено использование нескольких аккаунтов сразу. Одна из причин - это способ забрать промо коды у других людей, а также использовать бонусные программы для увеличения личного дохода. За использование нескольких аккаунтов могут применяться некоторые меры: блокировка промокодов, изъятие вещей, закрытие выводов, закрытие доступа к аккаунту'
        },
        {
          header: 'Почему при выводе скин возвращается в инвентарь?',
          text: 'Возможно в данный момент на маркете нет нужного предмета, в этом случае выберите другой предмет или подождите его появления. Также не забудьте проверить доступность трейдов на Вашем аккаунте.'
        },
        {
          header: 'Почему я не могу использовать промокод?',
          text: 'На нашем сайте ЗАПРЕЩЕНО ИМЕТЬ ДВА И БОЛЕЕ АККАУНТА- за это доступ к промокодам закрывается. Если у вас только один аккаунт, то, вероятнее всего, вы уже использовали сегодня промокод либо его лимит был исчерпан. Также промокоды могут быть заблокированы по усмотрению администриации за слив промокодов в чат'
        },
        {
          header: 'Почему не пришли деньги на баланс?',
          text: 'Подождите 5-30 минут, если проблема не решается, обратитесь в поддержку.'
        },
        {
          header: 'Как обменять баланс на предмет?',
          text: 'Нажмите кнопку «Размен» в правом нижнем углу экрана.'
        },
        {
          header: 'Как пополнить баланс?',
          text: 'Пополнить баланс можно, нажав на кнопку "Пополнить" в правой верхней части экрана любым удобным для вас способом, в том числе скинами'
        },
        {
          header: 'У вашего сайта есть группа ВКонтакте?',
          text: `Да, у нас есть группа <a href="${this.$root.config.vk_group}">ВКонтакте</a>`
        },
        {
          header: 'Где взять промокод?',
          text: `Промо-коды публикуются в нашей группе <a href="${this.$root.config.vk_group}">ВКонтакте</a>`
        }
      ]
    }
  },
}
</script>

<style lang="scss">
@import "~@/assets/css/mixins.scss";

.support {
  width: 100%;
  &__head {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;
    @include mobile{
      flex-flow: row nowrap;
    }
  }
}
</style>